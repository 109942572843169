<template>
  <div>
    <el-dialog
      title="优惠选择"
      :visible.sync="ticketShow"
      width="52%"
      :before-close="handleClose"
    >
      <div class="tableInput">
        <span>日期范围：</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date1"
          style="width: 20%"
        ></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date2"
          style="width: 20%"
        ></el-date-picker>
        <el-input
          v-model="QueryContent"
          placeholder="请输入内容"
          style="width: 25%; margin: 0 5px"
        ></el-input>
        <el-button type="success"><i class="el-icon-search"></i>查询</el-button>
        <el-button type="primary" @click="dialogT"
          ><i class="el-icon-s-promotion"></i>确认</el-button
        >
      </div>
      <div style="padding: 10px 0">
        <el-table
          ref="multipleTable2"
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column
            prop="created_at"
            sortable
            label="创建日期"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="coupon_name" label="名称" align="center">
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;1&nbsp;至&nbsp;{{
              tableFinshNum
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="1"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["ticketShow"],
  data() {
    return {
      dateTime: {
        date1: "",
        date2: "",
      },
      QueryContent: "",
      tableData: [],
      tableFinshNum: "",
      page: 1,
      length: 10,
      data: [],
      commercialId: "",
    };
  },
  mounted() {
    this.commercialId =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/coupon/couponList", {
        currentPage: this.page,
        currentLength: this.length,
        commercial_id: this.commercialId,
      })
      .then((res) => {
        this.tableData = res.data.data;
        this.tableFinshNum = res.data.count;
      });
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    dialogT() {
      this.$emit("addData", this.data);
    },
    handleSelectionChange(data) {
      this.data = data;
    },
  },
};
</script>
<style scoped></style>
