<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>单品活动</el-breadcrumb-item>
        <el-breadcrumb-item>{{ mybreadcrumb }}</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div style="padding: 50px; padding-right: 10%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <!-- 现金券 -->
        <div v-if="mybreadcrumb == '现金券'">
          <el-form-item label="名称：" prop="name" style="width: 35%">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="适用范围：" prop="application">
              <el-select
                v-model="ruleForm.application"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in applications"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="投放方式："
              prop="delivery"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.delivery"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in deliverys"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item
            label="优惠现金："
            prop="concessional"
            style="width: 35%"
          >
            <el-input v-model="ruleForm.concessional"></el-input>
          </el-form-item>
          <el-form-item
            label="发行张数："
            prop="circulation"
            style="width: 35%"
          >
            <el-input v-model="ruleForm.circulation"></el-input>
          </el-form-item>
          <el-form-item label="使用门槛：" prop="threshold" style="width: 35%">
            <el-input
              v-model="ruleForm.threshold"
              placeholder="消费满多少元可用"
            ></el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="领券起始日期："
              required
              style="width: 35%"
              label-width="100"
            >
              <el-date-picker
                v-model="ruleForm.time1"
                type="datetime"
                placeholder="选择日期时间"
                align="center"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="领券结束日期："
              required
              label-width="100"
              style="width: 35%"
            >
              <el-date-picker
                v-model="ruleForm.time2"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="有效期(天)：" prop="indate" style="width: 25%">
              <el-input
                v-model="ruleForm.indate"
                placeholder="0表示不限天数"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限领："
              prop="PersonLimit"
              style="width: 25%; margin-left: 10%"
            >
              <el-input
                v-model="ruleForm.PersonLimit"
                placeholder="0表示不限张数"
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="适用品牌：" prop="applicableBrand">
              <el-select
                v-model="ruleForm.applicableBrand"
                style="width: 80% !important"
              >
                <el-option
                  v-for="item in applicableBrands"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="适用商品："
              prop="commodity"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.commodity"
                style="width: 80% !important"
              >
                <el-option
                  v-for="item in commoditys"
                  :key="item.id"
                  :label="item.product_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="使用说明" prop="tip">
            <el-input v-model="ruleForm.tip"></el-input>
          </el-form-item>
        </div>
        <!-- 折扣券 -->
        <div v-if="mybreadcrumb == '折扣券'">
          <el-form-item label="名称：" prop="name" style="width: 35%">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="适用范围：" prop="application">
              <el-select
                v-model="ruleForm.application"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in applications"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="投放方式："
              prop="delivery"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.delivery"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in deliverys"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="优惠折扣：" prop="special" style="width: 35%">
            <el-input v-model="ruleForm.special"></el-input>
          </el-form-item>
          <el-form-item
            label="发行张数："
            prop="circulation"
            style="width: 35%"
          >
            <el-input v-model="ruleForm.circulation"></el-input>
          </el-form-item>
          <el-form-item label="使用门槛：" prop="threshold" style="width: 35%">
            <el-input
              v-model="ruleForm.threshold"
              placeholder="消费满多少元可用"
            ></el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="领券起始日期："
              required
              style="width: 35%"
              label-width="100"
            >
              <el-date-picker
                v-model="ruleForm.time1"
                type="datetime"
                placeholder="选择日期时间"
                align="center"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="领券结束日期："
              required
              label-width="100"
              style="width: 35%"
            >
              <el-date-picker
                v-model="ruleForm.time2"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="有效期(天)：" prop="indate" style="width: 25%">
              <el-input
                v-model="ruleForm.indate"
                placeholder="0表示不限天数"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限领："
              prop="PersonLimit"
              style="width: 25%; margin-left: 10%"
            >
              <el-input
                v-model="ruleForm.PersonLimit"
                placeholder="0表示不限张数"
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="适用品牌：" prop="applicableBrand">
              <el-select
                v-model="ruleForm.applicableBrand"
                style="width: 80% !important"
              >
                <el-option
                  v-for="item in applicableBrands"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="适用商品："
              prop="commodity"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.commodity"
                style="width: 80% !important"
              >
                <el-option
                  v-for="item in commoditys"
                  :key="item.id"
                  :label="item.product_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="使用说明" prop="tip">
            <el-input v-model="ruleForm.tip"></el-input>
          </el-form-item>
        </div>
        <!-- 商品券 -->
        <div v-if="mybreadcrumb == '商品券'">
          <el-form-item label="名称：" prop="name" style="width: 35%">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 65%"
          >
            <el-form-item label="适用范围：" prop="application">
              <el-select
                v-model="ruleForm.application"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in applications"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="投放方式："
              prop="delivery"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.delivery"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in deliverys"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="活动商品：" prop="events" style="width: 35%">
            <el-input
              v-model="ruleForm.events"
              @focus="eventsFocus"
              placeholder="点击添加商品"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="发行张数："
            prop="circulation"
            style="width: 35%"
          >
            <el-input v-model="ruleForm.circulation"></el-input>
          </el-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 68%"
          >
            <el-form-item label="领券起始日期：" required>
              <el-date-picker
                v-model="ruleForm.time1"
                type="datetime"
                placeholder="选择日期时间"
                align="center"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="领券结束日期：" required>
              <el-date-picker
                v-model="ruleForm.time2"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div
            style="display: flex; justify-content: space-between; width: 64%"
          >
            <el-form-item label="有效期(天)：" prop="indate">
              <el-input
                v-model="ruleForm.indate"
                placeholder="0表示不限天数"
              ></el-input>
            </el-form-item>
            <el-form-item label="每人限领：" prop="PersonLimit">
              <el-input
                v-model="ruleForm.PersonLimit"
                placeholder="0表示不限张数"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="使用说明" prop="tip">
            <el-input v-model="ruleForm.tip"></el-input>
          </el-form-item>
        </div>
        <!-- 卡券包 -->
        <div v-if="mybreadcrumb == '卡券包'">
          <el-form-item label="名称：" prop="name" style="width: 35%">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 65%"
          >
            <el-form-item label="适用范围：" prop="application">
              <el-select
                v-model="ruleForm.application"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in applications"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="投放方式："
              prop="delivery"
              style="margin-left: 8%"
            >
              <el-select
                v-model="ruleForm.delivery"
                style="width: 80% !important"
              >
                <el-option
                  v-for="(item, index) in deliverys"
                  :key="item"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item
            label="发行张数："
            prop="circulation"
            style="width: 35%"
          >
            <el-input v-model="ruleForm.circulation"></el-input>
          </el-form-item>
          <el-form-item label="活动清单：" prop="table">
            <el-table
              :data="ruleForm.tableData"
              border
              style="width: 80%; border: 1px solid #e5e5e5 !important"
            >
              <el-table-column prop="coupon_name" label="名称" align="center">
              </el-table-column>
              <el-table-column prop="num" label="数量" align="center">
                <template slot-scope="scope">
                  <el-input
                    v-model="ruleForm.inpArr[scope.$index]"
                    style="width: 50%"
                    @input="
                      inpChange(ruleForm.inpArr[scope.$index], scope.$index)
                    "
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column min-width="20" align="center">
                <template slot-scope="scope">
                  <i
                    class="el-icon-delete"
                    @click="tableClickClose(scope.$index)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" style="margin-top: 15px" @click="addClick"
              >添加商品</el-button
            >
          </el-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 68%"
          >
            <el-form-item label="领券起始日期：" required>
              <el-date-picker
                v-model="ruleForm.time1"
                type="datetime"
                placeholder="选择日期时间"
                align="center"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="领券结束日期：" required>
              <el-date-picker
                v-model="ruleForm.time2"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item label="使用说明" prop="tip">
            <el-input v-model="ruleForm.tip"></el-input>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <AddProduct
      :productShow="dialogVisible"
      @addConfirm="addConfirm"
      @handleClose="productClose"
    />
    <AddTicket
      :ticketShow="dialogVisible2"
      @handleClose="ticketClose"
      @addData="addData"
    />
  </div>
</template>
<script>
import { urlList } from "./data";
import { getDate } from "./utilse";
import AddProduct from "../../../../../addProduct/addProduct.vue";
import AddTicket from "../../../../../addTicket/addTicket.vue";
import qs from "qs";
export default {
  props: ["breadcrumb", "data"],
  components: { AddProduct, AddTicket },
  data() {
    return {
      ruleForm: {
        name: "",
        application: "",
        delivery: "",
        concessional: "",
        circulation: "",
        threshold: "",
        time1: "",
        time2: "",
        indate: "",
        PersonLimit: "",
        applicableBrand: "",
        commodity: "",
        tip: "每个商品在优惠选择时只能使用一张券（优惠券和单品券）优惠券和单品券不与满减活动同享",

        special: "",
        events: "",
        tableData: [],
        productId: "",
        inpArr: [],
      },
      rules: {
        name: [{ required: true, message: "这是必填字段" }],
        application: [{ required: true, message: "这是必填字段" }],
        delivery: [{ required: true, message: "这是必填字段" }],
        concessional: [{ required: true, message: "这是必填字段" }],
        circulation: [{ required: true, message: "这是必填字段" }],
        threshold: [{ required: true, message: "这是必填字段" }],
        indate: [{ required: true, message: "这是必填字段" }],
        PersonLimit: [{ required: true, message: "这是必填字段" }],
        special: [{ required: true, message: "这是必填字段" }],
        events: [{ required: true, message: "请选择你的商品！" }],
      },

      // 不同活动的新增
      applications: ["全场", "商城", "店内"],
      deliverys: ["自由领取", "活动投放"],
      applicableBrands: [],
      commoditys: [],

      // 弹窗
      isFileShow: false,
      dialogVisible: false,
      options1: [
        {
          value: "选项1",
          label: "cs1",
        },
      ],
      options2: [
        {
          value: "选项1",
          label: "cs2",
        },
      ],
      value1: "标签",
      value2: "类型",
      searchVal: "",
      isLabel: -1,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      tableData1: [
        { id: 1, uid: 1, name: "1", unit: "", marketPrice: "", salesPrice: "" },
        { id: 2, uid: 2, name: "2", unit: "", marketPrice: "", salesPrice: "" },
        { id: 3, uid: 3, name: "3", unit: "", marketPrice: "", salesPrice: "" },
      ],
      isResult: false,
      multipleSelections: [],
      tableFinshNum: 0,

      // 弹窗2
      dialogVisible2: false,
      type: "",
      id: 0,
    };
  },
  mounted() {
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.type = item.coupon_type;
      }
    });
    if (this.$props.data) {
      const data = this.$props.data;
      this.id = data.id;
      this.ruleForm.application = data.usable_range;
      this.ruleForm.delivery = data.put_mode;
      this.ruleForm.concessional = data.discounts_cash;
      this.ruleForm.circulation = data.copies_numbers;
      this.ruleForm.threshold = data.usabl_doorsill;
      this.ruleForm.time1 = data.card_satrt_date;
      this.ruleForm.time2 = data.card_end_date;
      this.ruleForm.indate = data.period_validity;
      this.ruleForm.PersonLimit = data.limited_purchase;
      this.ruleForm.applicableBrand = data.applicable_brand;
      this.ruleForm.commodity = data.applicable_product;
      this.ruleForm.tip = data.instructions;
      this.ruleForm.name = data.coupon_name;
      this.ruleForm.special = data.discounts_cash;
      this.ruleForm.events = data.product_arr?.product_name;
      this.ruleForm.productId = data.product_arr?.id;
      this.ruleForm.tableData = data.coupon_pid;
    }
    if (this.$props.data && this.$props.breadcrumb == "卡券包") {
      const data = this.$props.data;
      data.coupon_pid.forEach((item) => {
        this.ruleForm.inpArr.push(item.coupon_number);
      });
    }
    if (this.$props.breadcrumb !== "卡券包") {
      this.$http
        .post(
          "product/lst",
          qs.stringify({
            currentPage: 0,
            currentLength: 0,
          })
        )
        .then((res) => {
          this.commoditys = res.data.data;
        });
    }
  },
  computed: {
    mybreadcrumb() {
      return this.breadcrumb;
    },
  },
  methods: {
    closeClick() {
      this.$emit("close", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const subObj = {
            id: this.id,
            usable_range: this.ruleForm.application,
            put_mode: this.ruleForm.delivery,
            copies_numbers: this.ruleForm.circulation,
            card_satrt_date: getDate(this.ruleForm.time1),
            card_end_date: getDate(this.ruleForm.time2),
            instructions: this.ruleForm.tip,
            coupon_type: this.type,
            coupon_name: this.ruleForm.name,
          };
          if (this.$props.breadcrumb == "现金券") {
            subObj.discounts_cash = this.ruleForm.concessional;
            subObj.usabl_doorsill = this.ruleForm.threshold;
            subObj.applicable_brand = this.ruleForm.applicableBrand;
            subObj.applicable_product = this.ruleForm.commodity;
            subObj.period_validity = this.ruleForm.indate;
            subObj.limited_purchase = this.ruleForm.PersonLimit;
          } else if (this.$props.breadcrumb == "折扣券") {
            subObj.discounts_cash = this.ruleForm.special;
            subObj.usabl_doorsill = this.ruleForm.threshold;
            subObj.applicable_brand = this.ruleForm.applicableBrand;
            subObj.applicable_product = this.ruleForm.commodity;
            subObj.period_validity = this.ruleForm.indate;
            subObj.limited_purchase = this.ruleForm.PersonLimit;
          } else if (this.$props.breadcrumb == "商品券") {
            subObj.product_arr = this.ruleForm.productId;
            subObj.period_validity = this.ruleForm.indate;
            subObj.limited_purchase = this.ruleForm.PersonLimit;
          } else if (this.$props.breadcrumb == "卡券包") {
            subObj.coupon_pid = this.ruleForm.tableData;
          }
          this.$http.post("/coupon/save", subObj).then((res) => {
            if (res.data.status == "success") {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$emit("close", true);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.multipleSelections = val;
    },
    tableClickClose(i) {
      this.ruleForm.tableData.splice(i, 1);
      this.ruleForm.inpArr.splice(i, 1);
    },
    inpChange(data, index) {
      this.ruleForm.tableData[index].coupon_number = data;
    },
    pickerOptions() {
      console.log("pickerOptions");
    },
    eventsFocus() {
      this.dialogVisible = true;
    },
    addConfirm(data) {
      this.ruleForm.events = data[data.length - 1].product_name;
      this.ruleForm.productId = data[data.length - 1].id;
      this.dialogVisible = false;
    },
    productClose() {
      this.dialogVisible = false;
    },
    addClick() {
      this.dialogVisible2 = true;
    },
    ticketClose() {
      this.dialogVisible2 = false;
    },
    addData(data) {
      data.forEach((item) => {
        this.ruleForm.tableData.push(item);
        this.ruleForm.inpArr.push(1);
      });
      this.dialogVisible2 = false;
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  color: #333;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.dialogSelect {
  height: 32px;
}

.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.label {
  background-color: #ffeabf;
}

.table {
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  width: 50%;

  tr {
    th {
      border: 1px solid #e5e5e5;
    }

    td {
      border: 1px solid #e5e5e5;
      padding: 10px;
    }
  }
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
</style>

<style lang="less" scoped>
.el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}

.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}

.el-table {
  white-space: nowrap !important;
}
.el-table th div {
  white-space: nowrap !important;
}
</style>
